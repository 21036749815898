

<template>
  <v-container
    id="verify-email"
    class="fill-height justify-center"
    tag="section"
  >
    <Messages :messages="globalMessages"></Messages>

    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          :color="statusColor"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="display-2 font-weight-bold mb-2">
                Email verification
              </h1>
            </div>
          </template>
          <v-card-text class="text-center">
            {{statusText}}
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>

  </v-container>
</template>

<script>

import globalMixins from "../../mixins/globalMixins";

export default {
  name: 'PagesVerifyEmail',

  mixins: [globalMixins],

  components: {
    Messages: () => import('../../components/Messages')
  },

  props: {
    token: {
      type: String
    }
  },

  data: () => ({
    statusColor: '',
    statusText: '',
  }),

  mounted() {
    if (this.token) {
      const self = this;
      self.getSendData({
        'url': '/user/email/validate',
        'method': 'post',
        'headers': {
          'env': 'portal'
        },
        'params': {
          'token': self.token
        }
      }).then(() => {
        self.statusColor = 'success';
        self.statusText = 'Your new email address was successfully verified.';
      }).catch(() => {
        self.statusColor = 'error';
        self.statusText = 'We could not verify your email address.';
      });
    } else {
      this.statusColor = 'error';
      this.statusText = 'The email verification token is invalid.';
    }
  },
}
</script>
